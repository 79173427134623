/* global __lodash__, moment, Component, wrapPost, defaultState */
class carDeliveryListComponent extends Component {

    static name() {
        return "carDeliveryListCompacted";
    }

    static componentName() {
        return "carDeliveryListCompacted";
    }

    data() {
        return {
            editMode:false,
            selectedCode:null,
            searchText:null,
            Comment:null,
            deliveryListState:[],
            selectedShipMode:null,
            emptyCC: false,
            selectedCC: null,
            loadding:false,
            newDeliveryModal:false,
            commentSt:"",
            commentSO:""
        };
    }

    getWatch() {
        return  {
            'selectedShipMode':function (newValue,oldValue){
                this.emitEvent("shipModeChange",newValue);
                let filtered  = this.$store.state.shipModes.filter(x=>x.ShipModeCode == newValue);
                if(filtered.length > 0){
                    let daysByZone = filtered[0].ShipDays;
                    this.$store.commit('updateDaysByZone',daysByZone);
                }
            },
            "deliveryList":function (newValue,oldValue) {
                if(newValue && newValue.length == 1 && !this.selectedCode) {
                    this.selectedCode = newValue[0].Code;
                    this.$store.dispatch('updateOrderDeliveryData',{"order_DelAddressCode":newValue[0].Code});
                    this.activeAddress (this.selectedCode);
                }
            },
            'shipModes':function (newValue,oldValue){
                if(this.shipModes.length > 0) {
                    this.selectedShipMode = this.shipModes[0].ShipModeCode;
                }
            }
        };
    }

    mounted() {
        return async  function () {
            this.loadCustomerAddresses();
            this.$store.dispatch('updateOrderDeliveryData',{"order_ReceptionManagerCode": this.$store.getters.getSession.fields.Code});
            this.subscribeEvent("selectcentercost", this.centerCostCallback);
            this.subscribeEvent("emptycentercost", this.emptyCenterCostCallback);
            this.subscribeEvent("address-relocation",this.editAddress);
            this.subscribeEvent("address-relocation-cancel",this.editAddress);
            if(!this.selectedShipMode) {
                this.selectedShipMode = this.$store.state.order_ShipMode;
            }
        };
    }

    getComputed() {
        return {
            showCommentSO: function () {
                return this.$store.state.showCommentSO;
            },
            showCommentSt: function () {
                return this.$store.state.showCommentSt;
            },
            renderCommentSection: function () {
                return this.showCommentSO || this.showCommentSt;
            },
            shipModes: function () {
                return this.$store.getters.getShipModes;
            },
            canAddNew:function (){
                return (this.isAdmin || this.deliveryList.length == 0) && !this.loadding;
            },
            filterDeliveryList:function (){
                let searchText = this.searchText;
                if(!searchText || searchText == '')
                    return this.deliveryList;
                else
                    return __lodash__.filter(this.deliveryList, function (delivery) {
                        let reg = new RegExp(`${searchText}`,'i');
                        return String(delivery.Name).match(reg) ||  String(delivery.Address).match(reg) || String(delivery.City).match(reg) || String(delivery.Province).match(reg) || String(delivery.ZipCode).match(reg);
                    });
            },
            centerCostFilter:function (){
                if(this.selectedCC) {
                    return {masterId: this.selectedCC.internalId};
                }
                return {masterId:"-99999"};
            },
            deliveAddressByCustomer:function (){
                return this.$store.state.customer.DelAddressCode;
            },
            activeDayByZone:function (){
                let selectedShipMode = this.selectedShipMode;
                let shipMode = __lodash__.find(this.$store.state.shipModes,function (shipMode){
                    return shipMode.ShipModeCode == selectedShipMode;
                });
                if(shipMode && shipMode.OfficeCode){
                    return false;
                }

                let isDaysEnables = 0;
                let dayMap = this.$store.getters.getDaysByZone;
                for(let day of Object.keys(dayMap)) {
                    if (dayMap[day])
                        isDaysEnables += 1;
                }
                return isDaysEnables > 0;
            },
            daysByZone:function (){
                return this.$store.getters.getDaysByZone;
            },
            deliveryList:function () {
                return  this.$store.getters.getCustomerAddresses;
            },
            shipItemCode:function (){
                return this.$store.getters.getShipItemCode;
            },
            orderTemplate(){
                return  __lodash__.cloneDeep(this.$store.state.carOrderTemplate);
            },
            deliveryTime(){
                let timeList = this.$store.getters.getSetting.DeliveryTime;
                if (timeList.length > 0) {
                    this.$store.dispatch('updateOrderDeliveryData', {'order_DelHour': timeList[0].fields.From + '-' + timeList[0].fields.To})
                }
                return timeList;
            }
        };
    }

    getMethods() {
        return {
            editAddress:this.editAddress,
            loadCustomerAddresses:this.loadCustomerAddresses,
            activeDeliveryAddress:this.activeDeliveryAddress,
            activeAddress:this.activeAddress,
            updateSelectValues:this.updateSelectValues,
            openNewDelivery: this.openNewDelivery,
            createNewDelivey: this.createNewDelivey,
            openNewDeliveryModal:this.openNewDeliveryModal,
            centerCostCallback:this.centerCostCallback,
            emptyCenterCostCallback: this.emptyCenterCostCallback,
            calculateDate:this.calculateDate,
            buildComment:this.buildComment,
            isFreeShipCost:this.isFreeShipCost
        };
    }

    isFreeShipCost(freeShipCost){
        if(this.orderTemplate && this.orderTemplate.fields.Items && this.shipItemCode){
            let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
            let shipModeCodes = this.shipItemCode.split(",");
            let shipCostItem = __lodash__.find(items,function (cost){
                return shipModeCodes.indexOf(cost.fields.ArtCode) !== -1;
            });
            if(shipCostItem) {
                if (this.$store.getters.getSetting.ShowPriceWithIVA)
                    return this.orderTemplate.fields.Total - shipCostItem.fields.VATPrice >= freeShipCost;
                else
                    return this.orderTemplate.fields.SubTotal - shipCostItem.fields.Price >= freeShipCost;
            }
        }
        if(this.orderTemplate) {
            if (this.$store.getters.getSetting.ShowPriceWithIVA)
                return this.orderTemplate.fields.Total >= freeShipCost;
            else
                return this.orderTemplate.fields.SubTotal >= freeShipCost;
        }
        return false;
    }

    buildComment(){
        let comment = "";
        if(this.commentSt)
            comment = `${this.tr("Address Clarification:")}\n${this.commentSt}\n \n`;
        if(this.commentSO)
            comment = `${comment}${this.tr("Order Clarification:")}\n ${this.commentSO}.`;
        let eventObj = {};
        eventObj[`order_Comment`] = comment;
        this.$store.dispatch('updateOrderDeliveryData',eventObj);
    }

    async editAddress(address) {
        if(address.Longitude && address.Latitude && this.selectedCode == address.Code) {
            this.$store.dispatch('editAddressCustomer', address);
        }
    }

    async loadCustomerAddresses() {
        this.loading = true;
        this.$store.dispatch('toogleLockScreen',null);
        await this.$store.dispatch('loadCustomerAddresses');
        this.loading = false;
        this.$store.dispatch('toogleLockScreen',null);
    }

    activeDeliveryAddress(delCode){
        if(this.selectedCode != null) {
            if (this.selectedCode == delCode)
                return true;
        }
        else {
            if (this.deliveAddressByCustomer == delCode){
                //this.activeAddress (delCode);
                return true;
            }
        }
        return false;
    }

    activeAddress(code){
        this.editMode = !this.editMode;
        let filterDL = this.deliveryList.filter(x=>x.Code == code);
        if(filterDL.length > 0){
            let delObj = filterDL[0];
            if(delObj.ZipCode)
                this.$store.dispatch('loadZoneFromZipCode', delObj.ZipCode);
            else
                this.$store.commit('updateDaysByZone', defaultState.daysByZone);

            let eventObj = {};
            eventObj[`order_DelDay`] = null;
            this.$store.dispatch('updateOrderDeliveryData',eventObj);
            if(!delObj.Latitude || !delObj.Longitude)
                this.emitEvent("addressNeedConfirmations",delObj);
        }
        this.selectedCode = code;
        $("#resetPoint").scrollTop(0);
        this.$store.dispatch('updateOrderDeliveryData',{'order_DelAddressCode':code});
        this.emitEvent('deliveryselected',{'order_DelAddressCode':code});
    }

    updateSelectValues(updateValue){
        //console.log('Casdadasd',updateValue,event.target.value);
        let eventObj = {};
        eventObj[`order_${updateValue}`] = event.target.value;
        this.$store.dispatch('updateOrderDeliveryData',eventObj);
    }

    openNewDelivery(){
        //console.log( "New Delivery" );
        this.$emit('new-delivery');
    }

    createNewDelivey(payload){
        return new Promise((resolve, reject) => {
            let self = this;
            payload['EntityType'] = 0;
            payload['Entity'] = this.$store.state.customer.Code;
            payload['EntityName'] = this.$store.state.customer.Name;
            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/saveRecord`, {
                recordName: 'CompanyAddress',
                payload: payload
            })
                .then(function (response) {
                    if (response.data.success) {
                        self.deliveryList.push(response.data.record.fields);
                        self.activeAddress(response.data.record.fields.Code);
                    }
                    resolve(response.data);
                });
        });
    }

    openNewDeliveryModal(){
        this.newDeliveryModal = true;
        setTimeout(()=>{
            $("#newDeliveryModal").modal('show');
        },500);
        //$("#newDeliveryModal").modal('show');
    }

    emptyCenterCostCallback(event) {
        this.emptyCC = true;
        this.selectedCC = null;
        this.selectedCode = null;
    }

    centerCostCallback(event) {
        this.emptyCC = false;
        this.selectedCC = event;
        if(event.DelAddressCode) {
            this.selectedCode = event.DelAddressCode;
            this.activeAddress(event.DelAddressCode);
        }
    }

    calculateDate(days){
        moment.locale(this.$store.state.setting.DefaultLanguage);
        return moment().add(days,'days').format("dddd DD-MMM");
    }

    getTemplate() {
        return `<div class="col-12">
                  <template v-if="newDeliveryModal">
                    <addressCreateComponent />
                  </template>
                  <div class="col-12" >
                    <div class="mt-3 bordered reset-point" id="resetPoint">
                        <div class="row m-0 align-items-center">
                        <h6 class="col-12 col-lg-6 align-items-center d-flex">
                            <i class="delivery-icon fas fa-address-card fa-2x mr-3"></i>
                            <template v-if="editMode">
                                {{tr('Select a Delivery Address')}}
                            </template>
                            <template v-else>
                                {{tr('Delivery Address')}}
                            </template>
                        </h6>
                        <div v-if="canAddNew" class="col-12 col-lg-6 text-left text-lg-right">
                            <div class="my-2" v-on:click="openNewDelivery">
                                <button type="button" class="btn btn-light" @click="openNewDeliveryModal">
                                    <i class="fas fa-plus mr-2"></i>
                                    {{tr('New Delivery Address')}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div  class="row m-0 align-items-start">
                        <div v-if="editMode" class="input-group col-12 col-xl-6">
                                <input class="form-control" v-model="searchText">
                                <div class="input-group-prepend">
                                    <div class="input-group-text"><i class="fa fa-search" /></div>
                                </div>
                                <!--button class="btn btn-custom ml-sm-2 mt-2 mt-sm-0" @click="activeAddress(selectedCode)">Guardar</button -->
                            </div>
                            <div v-else="editMode" class="input-group col-12 col-xl-6">
                                <button v-if="filterDeliveryList.length > 1"  class="btn btn-link text-left p-0" @click="editMode=true">{{tr('Change delivery address')}}</button> 
                            </div>
                        </div>
                        <div v-if="filterDeliveryList.length > 0" class="row m-0 px-2 w-100">
                            <template v-if="!editMode">
                                <template v-for="delObj in deliveryList" >
                                    <template v-if="delObj.Code==selectedCode">
                                        <div :key="'deliver-selected'+delObj.Code" class="col-12  px-2">
                                            <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}"  >
                                              <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                              <p>{{delObj.Address}} </p>
                                              <p>{{delObj.Province}} </p>
                                              <p>{{delObj.City}} </p>
                                              <p>{{delObj.Locality}} </p>
                                              <p>{{delObj.ZipCode}} </p>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <template v-for="delObj in filterDeliveryList" >
                                    <div :key="'deliver-'+delObj.Code" class="col-12 col-lg-6 px-2">
                                        <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}" @click="selectedCode=delObj.Code, activeAddress(selectedCode)">
                                          <div class="col-12">
                                              <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                              <p>
                                                {{delObj.Street}} {{delObj.StreetNumber}} 
                                                <template v-if="delObj.Floor">{{delObj.Floor}}</template>
                                                <template v-if="delObj.Apartment">{{delObj.Apartment}}</template>
                                              </p>
                                              <p>{{delObj.Locality}}  {{delObj.ZipCode}} </p>
                                              <p>{{delObj.Province}}, {{delObj.City}} </p>
                                          </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="row new-address-message text-left text-md-right m-0" v-if="canAddNew">
                            <p class="col-12 mb-1">Puede administrar las direcciones en <router-link to="/profile/Addresses">Perfil > Direcciones</router-link></p>
                        </div>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2"  v-if="shipModes.length>0">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-truck fa-2x mr-3" style="font-size: 1.6em;"></i> {{tr('Reception Details')}}</h6>
                        <template v-for="shipMode of shipModes" >
                            <div :key="'shipMode-'+shipMode.ShipModeCode" class="col-12 col-lg-6 px-2">
                                <div class="address-card row m-0 my-2" :class="{selected:selectedShipMode==shipMode.ShipModeCode}" @click="selectedShipMode=shipMode.ShipModeCode">
                                  <template class="col-12" >
                                      <strong id="deliveryLabel"><i class="delivery-icon fas fa-truck mr-2"></i>{{shipMode.ShipModeName}}</strong>
                                      <p>
                                        {{tr('Cost')}}: 
                                        <template v-if="shipMode.FreeShipMinAmount">
                                            <template v-if="orderTemplate.fields.Total >= shipMode.FreeShipMinAmount">
                                                <del>$ {{shipMode.Cost}}</del>
                                            </template>
                                            <template v-else>
                                                $ {{shipMode.Cost}}
                                            </template>
                                        </template>
                                        <template v-else>
                                            $ {{shipMode.Cost}}
                                        </template>
                                      </p>
                                      <p v-if="shipMode.DelayMinDays && shipMode.DelayMaxDays">
                                        Llega entre {{calculateDate(shipMode.DelayMinDays)}} y el {{calculateDate(shipMode.DelayMaxDays)}}.
                                      </p>
                                      <p v-if="shipMode.FreeShipMinAmount">{{tr('Free shipping for purchases over')}} $ {{shipMode.FreeShipMinAmount}}.</p>
                                  </template>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="reception-details bordered form-row m-0 mb-2 px-2">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-dolly mr-3" style="font-size: 1.6em;"></i> {{tr('Reception Details')}}</h6>
                        <div class="col-12 row row-cols-1 row-cols-sm-2 m-0" :class="{'row-cols-xl-4': activeDayByZone, 'row-cols-xl-3': !activeDayByZone}">
                            <div class="form-group col px-1 d-none">
                                <label for="inputEmail4">{{tr('Ship Mode')}}</label>
                                <select class="custom-select mb-3" v-model="selectedShipMode" v-on:change="updateSelectValues('ShipMode')">
                                    <option value="null" selected>{{tr('Select One')}}</option>
                                    <template v-for="shipMode of shipModes">
                                        <option :value="shipMode.ShipModeCode" >{{shipMode.ShipModeName}}</option>
                                    </template v-for="shipMode of shipModes">
                                </select>
                            </div>
                            <div class="form-group col px-1" v-if="activeDayByZone">
                                <label for="inputEmail4">{{tr('Day')}}</label>
                                <select class="custom-select mb-3" v-on:change="updateSelectValues('DelDay')">
                                    <option value="null" :selected="$store.state.order_DelDay === null">{{tr('Select One')}}</option>
                                    <option value="1" v-if="daysByZone.Monday"    :selected="$store.state.order_DelDay === '1'">{{tr('Monday')}}</option>
                                    <option value="2" v-if="daysByZone.Tuesday"   :selected="$store.state.order_DelDay === '2'">{{tr('Tuesday')}}</option>
                                    <option value="3" v-if="daysByZone.Wednesday" :selected="$store.state.order_DelDay === '3'">{{tr('Wednesday')}}</option>
                                    <option value="4" v-if="daysByZone.Thursday"  :selected="$store.state.order_DelDay === '4'">{{tr('Thursday')}}</option>
                                    <option value="5" v-if="daysByZone.Friday"    :selected="$store.state.order_DelDay === '5'">{{tr('Friday')}}</option>
                                    <option value="6" v-if="daysByZone.Saturday"  :selected="$store.state.order_DelDay === '6'">{{tr('Saturday')}}</option>
                                    <option value="7" v-if="daysByZone.Sunday"    :selected="$store.state.order_DelDay === '7'">{{tr('Sunday')}}</option>
                                </select>
                            </div>
                            <div class="form-group col px-1">
                                <label for="inputEmail4" style="font-size: small">{{tr('Reception Manager')}}</label>
                                <template v-if="selectedCC">
                                    <selectLinkToComponent :recordKey="'Order_Delivery'"  class="form-control" table="CenterCostContactRow" paste="PersonCode" showField="PersonName" :selected="$store.state.session.fields.Code" :filters="centerCostFilter" @update-value="updateSelectValues('ReceptionManagerCode')"> </selectLinkToComponent>
                                </template>
                                <template v-else>
                                    <template v-if="emptyCC">
                                        <selectLinkToComponent :recordKey="'Order_Delivery'"  class="form-group" table="Person" paste="Code" showField="concat(Name, ' ',LastName)" :selected="$store.state.session.fields.Code" :filters="{CustCode:$store.state.session.fields.CustCode}" @update-value="updateSelectValues('ReceptionManagerCode')"> </selectLinkToComponent>
                                    </template>
                                    <template v-else>
                                        <select class="form-control mb-3 disabled" readonly disabled>
                                            <option :value="$store.state.session.fields.Code" selected> {{$store.state.session.fields.Name}} {{$store.state.session.fields.LastName}} </option>
                                        </select>
                                    </template>
                                </template>
                            </div>
                            <div class="form-group col px-1">
                                <label for="inputEmail4" style="font-size: small">{{tr('Delivery Schedule')}}</label>
                                <select class="custom-select mb-3" v-on:change="updateSelectValues('DelHour')">
                                    <option v-for="time in deliveryTime" :value="time.fields.From + '-' + time.fields.To">{{time.fields.From + " " + tr("to") + " " + time.fields.To}}</option> 
                                </select>
                            </div>
                            <div class="form-group col px-1">
                                <label for="inputEmail4" style="font-size: small">{{tr('Customer Purchase Order Number')}}</label>
                                <input class="form-control mb-3" v-on:change="updateSelectValues('CustPurchaseOrder')">
                            </div>
                        </div>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2" v-if="renderCommentSection">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-comment fa-2x mr-3" style="font-size: 1.6em;"></i> {{tr('Comments')}}</h6>
                        <div class="form-group col-12 col-md-6" :class="{'d-none':!showCommentSt}">
                           <label>{{tr('Additional information about the address')}}</label>
                           <textarea class="form-control" v-model="commentSt" rows="3" :placeholder="tr('Address observations, between which streets, other references...')" @change="buildComment" ></textarea>
                        </div>
                        <div class="form-group col-12 col-md-6" :class="{'d-none':!showCommentSO}">
                          <label>{{tr('Comments about the order')}}</label>
                          <textarea class="form-control" v-model="commentSO" rows="3" :placeholder="tr('Comments about the order, reception manager, etc...')" @change="buildComment" ></textarea>
                        </div>
                    </div>
                  </div>
            </div>`;
    }
}

carDeliveryListComponent.registerComponent();
